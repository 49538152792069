import Testimonials from "@/services/testimonials.service";

export default {
  namespaced: true,
  state: {
    testimonials: null,    
  },
  mutations: {
    testimonials(state, payload) {
      state.testimonials = payload;
    },
    reset(state){
      state.testimonials = null
    }
  },
  actions: {
    getTestimonials({ state, commit,rootState:{ language } }) {
      return Testimonials.getTestimonials(language.lang.siteId).then(({data}) => {
        const { testimonialsListEntries } = data;
        
        const homeTestimonials = [];
        const adapterTestimonials = [];
        const appTestimonials = [];
       
        for(let i = 0;i< testimonialsListEntries.length;i++){
          const currentTestimonial = testimonialsListEntries[i];

          if(currentTestimonial.testimonialCategory === "testimonialHome"){
            homeTestimonials.push({...currentTestimonial, active:false, order:homeTestimonials.length +1 });
          }

          if(currentTestimonial.testimonialCategory === "testimonialAdapter"){
            adapterTestimonials.push({...currentTestimonial, active:false, order:adapterTestimonials.length + 1 });
          }

          if(currentTestimonial.testimonialCategory === "testimonialApp"){
            appTestimonials.push({...currentTestimonial, active:false, order:appTestimonials.length + 1});
          }
        }

        commit( "testimonials" , { homeTestimonials, adapterTestimonials, appTestimonials });
        return state.testimonials;
      });
    },
  },
};
