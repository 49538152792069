import axios from './http.service';

class Testimonials {
  getTestimonials(siteId) {
    const query = {
      query: `
      {
        testimonialsListEntries(orderBy: "dateCreated DESC", siteId: "${siteId}") {
          ... on testimonialsList_testimonial_Entry {
            title
            description
            testimonialCategory
          }
        }
      }`
    }

    return axios.post(`${process.env.VUE_APP_API}/api`, query)
      .then(res => res.data);
  }
}

export default new Testimonials();
