import Pages from "@/services/pages.service";

export default {
  namespaced: true,
  state: {
    homepage: null,
    pricing: null,
    how_it_works: null,
    adapter: null,
    app: null,
    features: null,
    web_privacy: null,
    impressum: null,
    web_terms: null,
    supported_cars: null,
    app_privacy: null,
    app_terms: null,
    contact_us: null,
    tools: null,
    adapter_documents: null,
  },
  mutations: {
    homepage(state, payload) {
      state.homepage = payload;
    },
    pricing(state, payload) {
      state.pricing = payload;
    },
    how_it_works(state, payload) {
      state.how_it_works = payload;
    },
    adapter(state, payload) {
      state.adapter = payload;
    },
    app(state, payload) {
      state.app = payload;
    },
    features(state, payload) {
      state.features = payload;
    },
    impressum(state, payload) {
      state.impressum = payload;
    },
    web_privacy(state, payload) {
      state.web_privacy = payload;
    },
    web_terms(state, payload) {
      state.web_terms = payload;
    },
    supported_cars(state, payload) {
      state.supported_cars = payload;
    },
    app_privacy(state, payload) {
      state.app_privacy = payload;
    },
    app_terms(state, payload) {
      state.app_terms = payload;
    },
    contact_us(state, payload) {
      state.contact_us = payload;
    },
    tools(state, payload) {
      state.tools = payload;
    },
    adapter_documents(state, payload) {
      state.adapter_documents = payload;
    },
    reset(state) {
      state.homepage = null;
      state.pricing = null;
      state.how_it_works = null;
      state.adapter = null;
      state.app = null;
      state.features = null;
      state.supported_cars = null;
      state.contact_us = null;
      state.tools = null;
      state.adapter_documents = null;
    },
  },
  actions: {
    getPages({ state, commit, rootState: { language } }) {
      return Pages.getPages(language.lang.siteId).then(({ data }) => {
        if (data.pagesEntries.length) {
          const pages = data.pagesEntries;
          const newPages = [];

          newPages.push(pages.filter((page) => page.title === "homepage")[0]);
          newPages.push(pages.filter((page) => page.title === "pricing")[0]);
          newPages.push(
            pages.filter((page) => page.title === "how_it_works")[0]
          );
          newPages.push(pages.filter((page) => page.title === "adapter")[0]);
          newPages.push(pages.filter((page) => page.title === "app")[0]);
          newPages.push(pages.filter((page) => page.title === "features")[0]);
          newPages.push(pages.filter((page) => page.title === "impressum")[0]);
          newPages.push(
            pages.filter((page) => page.title === "web_privacy")[0]
          );
          newPages.push(pages.filter((page) => page.title === "web_terms")[0]);
          newPages.push(pages.filter((page) => page.title === "supported_cars")[0]);
          newPages.push(pages.filter((page) => page.title === "app_privacy")[0]);
          newPages.push(pages.filter((page) => page.title === "app_terms")[0]);
          newPages.push(pages.filter((page) => page.title === "contact_us")[0]);
          newPages.push(pages.filter((page) => page.title === "tools")[0]);
          newPages.push(pages.filter((page) => page.title === "adapter_documents")[0]);

          newPages.map((page) => {
            if (page.image1Url)
              page.image1Url = process.env.VUE_APP_API + page.image1Url;
            if (page.image2Url)
              page.image2Url = process.env.VUE_APP_API + page.image2Url;
            if (page.videoUrl)
              page.videoUrl = process.env.VUE_APP_API + page.videoUrl;
            page.items.map((item) => {
              if (item.imageUrl)
                item.imageUrl = process.env.VUE_APP_API + item.imageUrl;
              return item;
            });
            page?.children &&
              page.children.map((child) => {
                if (child.image1Url)
                  child.image1Url = process.env.VUE_APP_API + child.image1Url;
                if (child.image2Url)
                  child.image2Url = process.env.VUE_APP_API + child.image2Url;
                if (child.videoUrl)
                  child.videoUrl = process.env.VUE_APP_API + child.videoUrl;
                child.items.map((item) => {
                  if (item.imageUrl)
                    item.imageUrl = process.env.VUE_APP_API + item.imageUrl;
                  return item;
                });
                return child;
              });
            commit(page.title, page);
          });
        }
        return state;
      });
    },
  },
};
