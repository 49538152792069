import Faq from "@/services/faq.service";

export default {
  namespaced: true,
  state: {
    faq: null,
  },
  mutations: {
    faq(state, payload) {
      state.faq = payload;
    },
    reset(state){
      state.faq = null;
    }
  },
  actions: {
    getFaq({ state, commit,rootState:{ language } }) {
      return Faq.getFaq(language.lang.siteId).then(({data}) => {
        const { faqEntries } = data;

        const homeFaq = [];
        const adapterFaq = [];
        const appFaq = [];
        const contactUsFaq = [];

        for(let i = 0;i<faqEntries.length;i++){
          const currentFaq = faqEntries[i];

          if(currentFaq.faqCategory === "faqHome"){
            homeFaq.push({...currentFaq, active:false, order:homeFaq.length +1 });
          }

          if(currentFaq.faqCategory === "faqAdapter"){
            adapterFaq.push({...currentFaq, active:false, order:adapterFaq.length + 1 });
          }

          if(currentFaq.faqCategory === "faqApp"){
            appFaq.push({...currentFaq, active:false, order:appFaq.length + 1});
          }

          if(currentFaq.faqCategory === "faqContactUs"){
            contactUsFaq.push({...currentFaq, active:false, order:contactUsFaq.length + 1});
          }
        }

        commit( "faq" , { homeFaq, adapterFaq, appFaq, contactUsFaq });
        return state.faq;
      });
    },
  },
};
