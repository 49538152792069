import Prices from "@/services/prices.service";

export default {
  namespaced: true,
  state: {
    prices: null,
  },
  mutations: {
    prices(state, payload) {
      state.prices = payload;
    },
  },
  actions: {
    getPrices({ commit }, countryCode) {
      return Prices.getPrices(countryCode).then(({ data }) => {
        if (data.countriesEntries.length > 1) {
          const dataForCurrentCountry = data.countriesEntries.filter(
            (d) =>
              d.countryCode.toLocaleLowerCase() ===
              countryCode.toLocaleLowerCase()
          );

          commit("prices", dataForCurrentCountry[0]);
        } else {
          commit("prices", data.countriesEntries[0]);
        }
      });
    },
  },
};
