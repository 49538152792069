import axios from "./http.service";
import appConfig from "@/config/app.config";

class Prices {
  getPrices(countryCode) {
    // Default code 00 should be static, If there are 2 results, it will take this with the country code.
    // Prices should come only from siteId 1
    const query = {
      query: `
      {
        countriesEntries(countryCode: ["00", "${countryCode}"], siteId: "1") {
          ... on countries_country_Entry {
            title
            countryCode
            currency
            appPricePerMonth
            appPricePer3Months
            appPricePerYear
            adapterPrice
            evoAdapterPrice
            vendors {
              ... on vendors_path_BlockType {
                siteUrl
                evoSiteUrl
                siteName
                iconUrl
              }
            }
          }
        }
      }`,
    };

    return axios
      .post(`${process.env.VUE_APP_API}/api`, query, {
        headers: {
          Authorization: `Bearer ${appConfig.pricesToken}`,
        },
      })
      .then((res) => res.data);
  }
}

export default new Prices();
