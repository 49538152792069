import axios from "./http.service";

class Pages {
  getPages(siteId) {
    const query = {
      query: `
      {
        pagesEntries(level: 1, siteId: "${siteId}") {
          ... on pages_page_Entry{
            id
            title
            image1Url
            image1UrlAlt
            image1UrlTitle
            image2Url
            image2UrlAlt
            image2UrlTitle
            headings {
              ... on headings_h1_BlockType {
                h1Text1
                h1Text2
                h1Text3
                h1Text4
                h1Text5
                h1Text6
              }
              ... on headings_h2_BlockType {
                h2Text1
                h2Text2
                h2Text3
                h2Text4
                h2Text5
              }
              ... on headings_h3_BlockType {
                h3Text1
                h3Text2
                h3Text3
                h3Text4
                h3Text5
              }
              ... on headings_h4_BlockType {
                h4Text1
                h4Text2
                h4Text3
                h4Text4
                h4Text5
              }
              ... on headings_h5_BlockType {
                h5Text1
                h5Text2
                h5Text3
                h5Text4
                h5Text5
                h5Text6
                h5Text7
              }
            }
            items {
              ... on items_items_BlockType {
                itemTitle
                imageUrl
                imageUrlAlt
                imageUrlTitle
                text
              }
            }
            obdItems {
              ... on obdItems_obdItem_BlockType {
                item
              }
            }
            evoItems {
              ... on evoItems_evoItem_BlockType {
                item
              }
            }
            legalContent
            metaDescription
            metaDescription2
            metaKeywords
            metaTitle
            metaTitle2
            hreflangTag
            canonicalHref
            videoUrl
            children {
              ... on pages_page_Entry {
                title
                image1Url
                image1UrlAlt
                image1UrlTitle
                image2Url
                image2UrlAlt
                image2UrlTitle
                videoUrl
                metaDescription
                metaKeywords
                metaTitle
                hreflangTag
                canonicalHref
                items {
                  ... on items_items_BlockType {
                    itemTitle
                    imageUrl
                    imageUrlAlt
                    imageUrlTitle
                    text
                  }
                }
                obdItems {
                  ... on obdItems_obdItem_BlockType {
                    item
                  }
                }
                evoItems {
                  ... on evoItems_evoItem_BlockType {
                    item
                  }
                }
              }
            }
          }
        }
      }`,
    };

    return axios
      .post(`${process.env.VUE_APP_API}/api`, query)
      .then((res) => res.data);
  }
}

export default new Pages();
