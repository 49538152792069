import debounce from "lodash.debounce";
import memoize from "lodash.memoize";
import { OPEN_GRAPH_ADAPTER_IMG_URL } from "./constants";
import { store } from "../store/store";

/* eslint-disable */
export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function camelCaseToSnakeCase(str) {
  return str.replace(/([A-Z])/g, function ($1) {
    return "_" + $1.toLowerCase();
  });
}

export function userInitials(str) {
  const name = str.split(" ");
  return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ""}`;
}

export function mobileAndTabletCheck() {
  return screen.width <= 1030; /* 768 */
}

export function transitionDone() {
  return new Promise((resolve) => setTimeout(() => resolve(), 300));
}

export function memoizeDebounce(func, wait = 0, options = {}) {
  const mem = memoize(function () {
    return debounce(func, wait, options);
  }, options.resolver);
  return function () {
    mem.apply(this, arguments).apply(this, arguments);
  };
}

export function compareObjects(object1, object2) {
  if (object1.length !== object2.length) return false;
  for (let key in object1) {
    if (key === "desired_availability") {
      if (compareArrays(object1[key], object2[key])) {
        return false;
      }
      continue;
    }
    if (object1[key] !== object2[key]) return false;
  }
  return true;
}

export function compareArrays(array1, array2) {
  if (array1.length !== array2.length) return true;
  return JSON.stringify(array1) !== JSON.stringify(array2);
}

export function rangeYears(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => {
      return { name: end - idx, id: end - idx };
    });
}

export function isClassActive(currentClass, active) {
  return active ? currentClass + " active" : currentClass;
}

export function detectSamsungBrowser() {
  return navigator.userAgent.match(/SamsungBrowser/i);
}

export function convertPriceToNumber(price) {
  return Number(price?.replace("$", "").replace(",", ""));
}

export function validateEmail(email) {
  const regex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  if (regex.test(email)) return true;
  return false;
}

export function getCookie(name) {
  let cookie = {};
  document.cookie.split(";").forEach(function (el) {
    let [k, v] = el.split("=");
    if (k.trim() === 'CookieConsent') {
      const arr = el.split("={");
      cookie[k.trim()] = arr[1];
    } else {
      cookie[k.trim()] = v;
    }
  });
  return Object.prototype.hasOwnProperty.call(cookie, name)
    ? cookie[name]
    : null;
}

export function facebookGoogleAnalitycs(eventName, params) {
  const cookieSettings = getCookie("CookieConsent");

  if (cookieSettings) {
    const arr = cookieSettings.split('%2C');
    const statistics = arr[3].split(":")[1] === 'true';
    const marketing = arr[4].split(":")[1] === 'true';

    if (statistics || marketing) {
      try {
        fbq("trackCustom", eventName, params);
      } catch (err) {}
      try {
        // gtag("event", eventName, params);
        dataLayer.push({'event': eventName, ...params});
      } catch (err) {}
    }
  }
}

export function createMetaInfo({ title = "Carista", pageMeta = {} }) {
  const meta = [];
  const link = [];

  if (pageMeta?.metaDescription) {
    meta.push({
      name: "description",
      content: pageMeta?.metaDescription,
    });
  }

  if (pageMeta?.metaKeywords) {
    meta.push({
      name: "keywords",
      content: pageMeta?.metaKeywords,
    });
  }

  if (pageMeta?.canonicalHref) {
    link.push({ rel: "canonical", href: pageMeta?.canonicalHref });
  }

  meta.push({
    name: "robots",
    content: "index, follow",
  });

  // // Open Graph tags
  // document.querySelector('[property="og:title"]').setAttribute("content", title);
  // document.querySelector('[property="og:description"]')
  //   .setAttribute("content", pageMeta?.metaDescription);

  // if (pageMeta?.canonicalHref && pageMeta?.canonicalHref.includes('/blog/')) {
  //   document.querySelector('[property="og:image"]').setAttribute("content", pageMeta?.articleImgUrl);
  //   document.querySelector('[property="og:image:secure_url"]').setAttribute("content", pageMeta?.articleImgUrl);
  //   document.querySelector('[property="og:image:alt"]').setAttribute("content", pageMeta?.articleImgAlt);
  // }

  return {
    title,
    meta,
    link,
  };
}

export function addParamsToStringFromCMS(cmsString = '', paramsArray = []) {
  let result = cmsString;
  const stringArray = cmsString.split(/{{|}}/);

  if (stringArray.length > 1 && paramsArray.length > 0) {
    result = `${stringArray[0]}`;
    for (let i = 0; i < paramsArray.length; i++) {
      result += `${paramsArray[i] ? paramsArray[i] : stringArray[i + 1]}${stringArray[i + 2]}`;
    }
  }

  return result;
}

export function blogCreatedAtFormatted(createdAtDate) {
  const date = new Date(createdAtDate).toLocaleString(
    'en-us',
    { month:'long', day: 'numeric', year:'numeric' }
  );
  return date;
}

export function copyBlogArticleLink(article) {
    const link = `${process.env.VUE_APP_APP}/blog/${article}`;
    navigator.clipboard.writeText(link);
}

export function getCurrentYear() {
  return new Date().getFullYear();
}

export function getLangFromCountryCode(countryCode) {
  let lang = 'en';

  // Germany, Austria, Switzerland, Liechtenstein
  if (['de', 'at', 'ch', 'li'].includes(countryCode)) {
    lang = 'de';
  }

  // Japan
  if (['jp'].includes(countryCode)) {
    lang = 'ja';
  }

  // Russia, Kazakhstan, Belarus, Kyrgyzstan
  if (['ru', 'kz', 'by', 'kg'].includes(countryCode)) {
    lang = 'ru';
  }

  // Spain, Mexico, Argentina, Colombia, Chile, Peru, Venezuela, Ecuador, Guatemala, Cuba
  // Dominican Republic, Honduras, El Salvador, Nicaragua, Costa Rica, Puerto Rico, Panama, Equatorial Guinea
  if (['es', 'mx', 'ar', 'co', 'cl', 'pe', 've', 'ec', 'gt', 'cu', 'do', 'hn', 'sv', 'ni', 'cr', 'pr', 'pa', 'gq']
    .includes(countryCode)) {
    lang = 'es';
  }

  // Italian, San Marino
  if (['it', 'sm'].includes(countryCode)) {
    lang = 'it';
  }

  // France, Belgium, Luxembourg, Monaco, Haiti
  if (['fr', 'be', 'lu', 'mc', 'ht'].includes(countryCode)) {
    lang = 'fr';
  }

  return lang;
}

export function isPricingPage(currentRoute) {
  return currentRoute === 'pricing' ||
    currentRoute === 'pricing-ford' ||
    currentRoute === 'pricing-sfd' ||
    currentRoute === 'pricing-non-sfd' ||
    currentRoute === 'pricing-bmw';
}

export function pathToPricing(langCode = 'en', make, isSFD) {
  let stringAfterPricing = '';
  const isVag =
    make === 'audi' ||
    make === 'cupra' ||
    make === 'seat' ||
    make === 'skoda' ||
    make === 'vw';

  if (make === 'ford' || make === 'lincoln' || make === 'land-rover') {
    stringAfterPricing += '-ford';
  } else if (make === 'bmw' || make === 'mini') {
    stringAfterPricing += '-bmw';
  } else if (isVag && isSFD === false) {
    // if we know the car is not SFD, go to Pricing non-SFD
    stringAfterPricing += '-vw-group-non-sfd-models';
  } else if (isVag) {
    // if isSFD is undefined, go to Pricing SFD
    stringAfterPricing += '-vw-group-sfd-models';
  }

  return `/${langCode}/pricing${stringAfterPricing}`;
}

export function getMobileOS() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
  }

  return null;
}

export function openModal(modalName, option) {
  store.dispatch("modals/openModal", {
    currentModal: `is${modalName}ModalOpen`,
    data: option,
  });
}
