<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script setup>
import { onUnmounted, onBeforeMount } from 'vue';
import { useStore } from "vuex";

const store = useStore();

const modalsController = (keyCode) => {
  if (keyCode === 27) store.dispatch('resetModalStates');
  if (keyCode === 37) store.dispatch('modals/prevModal');
  if (keyCode === 39) store.dispatch('modals/nextModal');
};

onBeforeMount(() => {
  setTimeout(() => {
    store.dispatch('init');
    document.addEventListener('keyup', (e) => modalsController(e.keyCode));
  }, 1000);
});

onUnmounted(() => {
  document.removeEventListener('keyup', modalsController);
});
</script>
