import axios from './http.service';

class Faq {
  getFaq(siteId = 1) {
    const query = {
      query: `
      {
        faqEntries(orderBy: "dateCreated DESC", siteId: "${siteId}") {
          ... on faq_faq_question_Entry {
            title
            faqAnswer
            faqCategory
          }
        }
      }`
    }

    return axios.post(`${process.env.VUE_APP_API}/api`, query)
      .then(res => res.data);
  }
}

export default new Faq();
